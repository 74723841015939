//#region ⭐模組需求套件--------------------------------------------------------
import './styles.css';


import 'antd/dist/reset.css';
import Paper from '@mui/material/Paper';
import { WarningOutlined,DollarCircleOutlined,CodeSandboxOutlined,DropboxOutlined,IeOutlined,SearchOutlined } from '@ant-design/icons';
import { UserOutlined,AlertOutlined,LockOutlined,PlusOutlined,DollarOutlined,LoadingOutlined,BarcodeOutlined,CheckOutlined,RedoOutlined } from '@ant-design/icons';
import { Space , Button, Select, Table , Input , Divider, DatePicker , Tabs  , InputNumber,Typography,Card,Form,message, Popconfirm,Row,Col,notification,Statistic  } from "antd";
import { useRef,useState,useEffect } from "react";
import Axios from "axios";
import moment from 'moment';
const { TextArea } = Input;
//#endregion 
export default 結帳模組


function 結帳模組(adminvalue){

  const [STATE_網頁讀取, set_STATE_網頁讀取] = useState("未讀取");
  const [STATE_正在結帳, set_STATE_正在結帳] = useState(true);


    //#region 延遲按鈕 
  const [loadings, setLoadings] = useState([]);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 2000);
  };

  //#endregion

    //#region 初始日期模組
    const currentDate = new Date() 
    if (currentDate.getHours() < 7)  { currentDate.setDate(currentDate.getDate() - 1) } 
    const 日期 = moment(currentDate).format("YYYY-MM-DD")
    const 昨天 = moment(currentDate).subtract(1, 'days').format("YYYY-MM-DD")
    const 完整日期 = moment(currentDate)
    //#endregion
    // #region 變數值-------------------------------------------------------------------------------
    const [預覽結帳單, set_預覽結帳單] = useState([]);

    const [門市, set_門市] = useState(adminvalue.adminvalue[1])
    const [承辦, set_承辦] = useState("");
    const [備註, set_備註] = useState("");

    const [刮樂銷售, set_刮樂銷售] = useState(0);
    const [台彩銷售, set_台彩銷售] = useState(0);
    const [台彩兌獎_電腦型, set_台彩兌獎_電腦型] = useState(0);
    const [台彩兌獎_立即型, set_台彩兌獎_立即型] = useState(0);
    const [運彩銷售, set_運彩銷售] = useState(0);
    const [運彩兌獎_有效, set_運彩兌獎_有效] = useState(0);
    const [運彩兌獎_無效, set_運彩兌獎_無效] = useState(0);

    const [金流代收, set_金流代收] = useState(0);
    const [金流代付, set_金流代付] = useState(0);
    const [刮樂批入, set_刮樂批入] = useState(0);
    const [刮樂批出, set_刮樂批出] = useState(0);


    const [種類_未授權, set_種類_未授權] = useState("");
    const [銷售日期, set_銷售日期] = useState("");
    const [面額0796, set_面額_0796] = useState("");
    const [面額08, set_面額_08] = useState("");
    const [備註_未授權, set_備註_未授權] = useState("");

    // const [應有前期, set_應有前期] = useState(0); 
    const [額度增減, set_額度增減] = useState(0);
    const [盤點後期, set_盤點後期] = useState(0);
    const [額度增加, set_額度增加] = useState(0);
    const [額度減少, set_額度減少] = useState(0);

    const [台幣現值, set_台幣現值] = useState(0);
    const [等價現值, set_等價現值] = useState(0);
    const [刮樂現值, set_刮樂現值] = useState(0);



    const [現金0001, set_現金0001] = useState(0);
    const [現金0005, set_現金0005] = useState(0);
    const [現金0010, set_現金0010] = useState(0);
    const [現金0050, set_現金0050] = useState(0);
    const [現金0100, set_現金0100] = useState(0);
    const [現金0200, set_現金0200] = useState(0);
    const [現金0500, set_現金0500] = useState(0);
    const [現金1000, set_現金1000] = useState(0);
    const [現金2000, set_現金2000] = useState(0);



    const [種類, set_種類] = useState("4001／檢查用／$100");

    // #endregion
    // #region 清單值
    const [cashflow_list, set_cashflow_list] = useState([]);
    const [logistic_list, set_logistic_list] = useState([]);
    const [member_list , set_member_list] = useState([]);
    const [member_list_staff , set_member_staff_list] = useState([]);
    const [item_list , set_item_list] = useState([])
    const [item_list_計算用 , set_item_list_計算用] = useState([]);
    const [dayreport_list , set_dayreport_list] = useState([]);
    const [dayinventory_list , set_dayinventory_list] = useState([]);
      const [dayinventory_yesterday_list , set_dayinventory_yesterday_list] = useState([]);
      const [dayinventory_today_list , set_dayinventory_today_list] = useState([]);
    const [unauthorized_list , set_unauthorized_list] = useState([]);
    // #endregion
    // #region 更新值
   
     useEffect(() => // 立即型張數驗算機制
      {  
        var today = dayinventory_today_list.map(data => ({ ...data, "前期":0 , "批動":0 ,"理論銷售":0})); 
        for(const key in today)
        {
          var targetData1 = dayinventory_yesterday_list.filter(item => item.種類 === today[key].種類)[0] 
          if(targetData1 === undefined){ targetData1=0}  else{today[key].前期 = targetData1.數量 } 
      
          var targetData2 = logistic_list.filter(item => item.物品 === today[key].種類)
          if(targetData2 === undefined){ targetData2=0}  else{today[key].批動 = targetData2.reduce((acc, data) => acc + data.存貨增加-data.存貨減少, 0) } 
        
          today[key].理論銷售 = today[key].前期+today[key].批動-today[key].數量
          
        }
        set_dayinventory_list(today) 
      
      }, [dayinventory_today_list,logistic_list])



      useEffect(() => { // set_預覽結帳單
        set_預覽結帳單(prevState => ({
          ...prevState,
            台幣現值: 台幣現值,
            等價現值: 等價現值,
            刮樂現值: 刮樂現值,
          }));
        
      }, [台幣現值, 等價現值, 刮樂現值]);

      useEffect(() => // 額度增加
      {  set_額度增加(台彩銷售+運彩銷售+金流代收+刮樂批入)
      }, [台彩銷售+運彩銷售+金流代收+刮樂批入])
      useEffect(() => // 額度減少
      {  set_額度減少(台彩兌獎_電腦型+台彩兌獎_立即型+運彩兌獎_有效+運彩兌獎_無效+金流代付+刮樂批出)
      }, [台彩兌獎_電腦型+台彩兌獎_立即型+運彩兌獎_有效+運彩兌獎_無效+金流代付+刮樂批出])
      useEffect(() => // 額度增減
      {  set_額度增減(額度增加-額度減少)
      }, [額度增加-額度減少])
      useEffect(() => // 台幣現值
      {  set_台幣現值(現金0001+現金0005*5+現金0010*10+現金0050*50+現金0100*100+現金0200*200+現金0500*500+現金1000*1000+現金2000*2000)
      }, [現金0001+現金0005+現金0010+現金0050+現金0100+現金0200+現金0500+現金1000+現金2000])
      useEffect(() => // 盤點後期
      {  set_盤點後期(刮樂現值+等價現值+台幣現值)
      }, [刮樂現值+等價現值+台幣現值])


      useEffect(() => // 等價現值
      {  set_等價現值(unauthorized_list.reduce((accumulator, currentValue) => {return accumulator + currentValue.面額0796;}, 0))
      }, [unauthorized_list])

      useEffect(() => // 刮樂現值
      {  set_刮樂現值(dayinventory_list.reduce((accumulator, currentValue) => { 
        const item = item_list_計算用.find((item) => item.items_name === currentValue.種類); 
        return accumulator + currentValue.數量*(item.denomination)}, 0))
      }, [dayinventory_list])

      useEffect(() => // 刮樂銷售
      {  if(dayreport_list.length>0){const sum = dayreport_list[0].刮樂現值-刮樂現值+刮樂批入-刮樂批出 ; set_刮樂銷售(sum) }}, [dayreport_list,刮樂現值,刮樂批入,刮樂批出])



    // #endregion
    // #region R功能
    const read_dayreport = async() => {await Axios.get("https://pos-back-end.pktriot.net/dayreport_read" + '?日期=' + 日期 + '&門市=' + 門市).then((response) => {set_dayreport_list(response.data)})}
    
    const read_cashflow = async () => {
      let response = await Axios.put("https://pos-back-end.pktriot.net/cashflow_read", {
        日期: adminvalue.adminvalue[0],
        門市: adminvalue.adminvalue[1],})
    
      Object.keys(response.data)
      .forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp)
      .toLocaleString('zh-TW', {hour12: false})
      .replace(/\//g, "-")})
    
      set_cashflow_list(response.data)
      return response.data
    }
    const read_logistic = async() => {
      let response = await Axios.put("https://pos-back-end.pktriot.net/logistic_read", {
        日期: adminvalue.adminvalue[0],
        門市: adminvalue.adminvalue[1],})
      Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp).toLocaleString('zh-TW', {hour12: false});});
      set_logistic_list(response.data)
      return response.data
    }
    const read_item = async() => {
      Axios.get("https://pos-back-end.pktriot.net/item_read").then((response) => {const newarray = response.data.map((item) => {return {value:item.items_name,label:item.items_name ,}});
      set_item_list_計算用(response.data)
      set_item_list(newarray)});
    }
    const read_member = async() => {
      let response = await Axios.put("https://pos-back-end.pktriot.net/member_read",{門市: 門市,})
      const newarray = response.data.map((item) => {return {value:item.名稱,label:item.名稱 ,}})
      set_member_list(newarray)
      const staff = newarray.filter((item) => item.label.startsWith('ST'));
      set_member_staff_list(staff)
    
    }
    const read_dayinventory = async () => {
      setTimeout(async () => {
    
        await Axios.get("https://pos-back-end.pktriot.net/dayinventory_read" + '?日期=' + 日期 + '&門市=' + 門市)
          .then((response) => {
            // 按照面額從大到小排序資料
            const sortedData = response.data.sort((a, b) => b.面額 - a.面額);
            set_dayinventory_today_list(sortedData);
          });
    
        await Axios.get("https://pos-back-end.pktriot.net/dayinventory_read" + '?日期=' + 昨天 + '&門市=' + 門市)
          .then((response) => {
            // 按照面額從大到小排序資料
            const sortedData = response.data.sort((a, b) => b.面額 - a.面額);
            set_dayinventory_yesterday_list(sortedData);
          });
    
      }, 200);
    };
    
    const read_unauthorized = async() => {
      Axios.get("https://pos-back-end.pktriot.net/unauthorized_read" + '?日期=' + 日期 + '&門市=' + 門市).then((response) => {
      Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].銷售日期;response.data[key].銷售日期 = new Date(timestamp).toLocaleString('zh-TW', {year: 'numeric',month: 'numeric',day: 'numeric'})})
      Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].入帳日期;response.data[key].入帳日期 = new Date(timestamp).toLocaleString('zh-TW', {year: 'numeric',month: 'numeric',day: 'numeric'})})
      Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].出帳日期;response.data[key].出帳日期 = new Date(timestamp).toLocaleString('zh-TW', {year: 'numeric',month: 'numeric',day: 'numeric'})})
      Object.keys(response.data).forEach((key) => {if(response.data[key].出帳日期==="1970/1/1"){response.data[key].出帳日期=""}})
      set_unauthorized_list(response.data)})
    }
    // #endregion
    // #region C功能集-------------------------------------------------------------------------------
    const create_dayreport = (form_object) => {Axios.post("https://pos-back-end.pktriot.net/dayreport_create", {
        日期: 日期,
        門市: 門市,
        承辦: form_object.承辦,
        備註: form_object.備註,

        帳差:盤點後期-(dayreport_list[0].台幣現值+dayreport_list[0].等價現值+dayreport_list[0].刮樂現值+額度增減),
    
        刮樂銷售: 刮樂銷售,
        台幣現值: form_object.台幣現值 || 0,
        等價現值: form_object.等價現值 || 0,
        刮樂現值: form_object.刮樂現值 || 0,
    
        台彩銷售: form_object.台彩銷售,
        台彩兌獎_電腦型: form_object.台彩兌獎_電腦型,
        台彩兌獎_立即型: form_object.台彩兌獎_立即型,
        運彩銷售: form_object.運彩銷售,
        運彩兌獎_有效: form_object.運彩兌獎_有效,
        運彩兌獎_無效: form_object.運彩兌獎_無效,
    
        
        現金0001: form_object.現金0001,
        現金0005: form_object.現金0005,
        現金0010: form_object.現金0010,
        現金0050: form_object.現金0050,
        現金0100: form_object.現金0100,
        現金0200: form_object.現金0200,
        現金0500: form_object.現金0500,
        現金1000: form_object.現金1000,
        現金2000: form_object.現金2000,
        
    
      }).then((response) => {set_dayreport_list(response.data)})}
    
    const create_dayinventory = () => {

        Axios.post("https://pos-back-end.pktriot.net/dayinventory_create", {
          日期: 日期,
          門市: 門市,
          種類: 種類,
        }).then((response) => {read_dayinventory()})
    }
    
    const dayinventory_create_default = () => {
      
      Axios.post("https://pos-back-end.pktriot.net/dayinventory_create_default", {
      日期: 日期,
      門市: 門市,
      }).then((response) => {read_dayinventory()})} 
    const dayinventory_create_default_beta = () => {
    
      Axios.post("https://pos-back-end.pktriot.net/dayinventory_create_default_beta", {
      日期: 日期,
      門市: 門市,
      }).then((response) => {read_dayinventory()})}   



    const create_unauthorized = () => {Axios.post("https://pos-back-end.pktriot.net/unauthorized_create", {
      門市: 門市,
      種類: 種類_未授權,
      面額0796: 面額0796,
      面額08: 面額08,
      銷售日期: 銷售日期,
      入帳日期: 日期,
      備註: 備註_未授權,
    }).then(read_unauthorized)}
    
    // #endregion
    // #region U功能集-------------------------------------------------------------------------------
    const update_dayinventory = (e,record) => {
      Axios.put("https://pos-back-end.pktriot.net/dayinventory_update", {
        INVENTORY_ID: record.INVENTORY_ID,
        日期: 日期,
        門市: 門市,
        種類: record.種類,
        數量: e,
        備註: record.備註,
    })}
    
    const update_unauthorized = (table_object) => {
      Axios.put("https://pos-back-end.pktriot.net/unauthorized_update", {
        unauthorized_ID: table_object.unauthorized_ID,
        種類: table_object.種類,
        備註: table_object.備註,
      }).then(read_unauthorized)}
    
    const update_dayrecord = (table_object) => {
      Axios.put("https://pos-back-end.pktriot.net/dayrecord_update", {
        RECORD_ID: table_object.RECORD_ID,
        壹元: table_object.壹元,
        伍元: table_object.伍元,
        拾元: table_object.拾元,
        伍拾元: table_object.伍拾元,
        佰元: table_object.佰元,
        貳佰元: table_object.貳佰元,
        伍佰元: table_object.伍佰元,
        仟元: table_object.仟元,
        貳仟元: table_object.貳仟元,
        未授權台彩: table_object.未授權台彩,
        未授權運彩: table_object.未授權運彩,
        其他: table_object.其他,
        台彩銷售: table_object.台彩銷售,
        台彩兌獎_電腦型: table_object.台彩兌獎_電腦型,
        台彩兌獎_立即型: table_object.台彩兌獎_立即型,
        運彩銷售: table_object.運彩銷售,
        運彩兌獎_有效: table_object.運彩兌獎_有效,
        運彩兌獎_無效: table_object.運彩兌獎_無效,
        結帳備註: table_object.結帳備註,
      }).then(read_dayinventory)
    }
    // #endregion
    // #region D功能集-------------------------------------------------------------------------------
    const delete_dayinventory = (table_object) => {
      Axios.delete(`https://pos-back-end.pktriot.net/dayinventory_delete/${table_object.INVENTORY_ID}`).then(read_dayinventory)}
    const delete_unauthorized = (table_object) => {
      Axios.put("https://pos-back-end.pktriot.net/unauthorized_update_out", {
        unauthorized_ID: table_object.unauthorized_ID,
        出帳日期:日期,
      }).then(read_unauthorized)}
    
    // #endregion
    // #region 其他功能集
    const 重載金物 = async() => {
      let cashflow_list = await read_cashflow()
      let logistic_list = await read_logistic()
      
      let date = new Date(日期); // 比較的日期，使用 Date 物件
    
      const totalAmount1 =  cashflow_list.filter(item => {
        let timestamp_date = new Date(item.TIMESTAMP_ID)
        return timestamp_date.getFullYear() === date.getFullYear() && timestamp_date.getMonth() === date.getMonth() && timestamp_date.getDate() === date.getDate(); // 比較日期是否相同
      }).reduce((accumulator, current) => accumulator + current.額度增加,0)
      
      const totalAmount2 = cashflow_list.filter(item => {
        let timestamp_date = new Date(item.TIMESTAMP_ID); // 將 TIMESTAMP_ID 欄位轉換為 Date 物件
        return timestamp_date.getFullYear() === date.getFullYear() && timestamp_date.getMonth() === date.getMonth() && timestamp_date.getDate() === date.getDate(); // 比較日期是否相同
      }).reduce((accumulator, current) => accumulator + current.額度減少,0)
    
      const totalAmount3 = logistic_list.filter(item => {
        let timestamp_date = new Date(item.TIMESTAMP_ID); // 將 TIMESTAMP_ID 欄位轉換為 Date 物件
        return timestamp_date.getFullYear() === date.getFullYear() && timestamp_date.getMonth() === date.getMonth() && timestamp_date.getDate() === date.getDate(); // 比較日期是否相同
      }).reduce((accumulator, current) => accumulator + current.額度增加,0)
    
      const totalAmount4 = logistic_list.filter(item => {
        let timestamp_date = new Date(item.TIMESTAMP_ID); // 將 TIMESTAMP_ID 欄位轉換為 Date 物件
        return timestamp_date.getFullYear() === date.getFullYear() && timestamp_date.getMonth() === date.getMonth() && timestamp_date.getDate() === date.getDate(); // 比較日期是否相同
      }).reduce((accumulator, current) => accumulator + current.額度減少,0)
    
    
      set_金流代收(totalAmount1)
      set_金流代付(totalAmount2)
      set_刮樂批入(totalAmount3)
      set_刮樂批出(totalAmount4)


      }
      const 完成結帳 = async (form_data)=> {
        const new_data = {
          ...form_data, // 使用展開運算符將 form_data 中的屬性複製到新對象中
          台幣現值: 台幣現值,
          等價現值: 等價現值,
          刮樂現值: 刮樂現值,
        };
        await create_dayreport(new_data)
      }
    const 預覽結帳 = async (form_data)=> {
      await loadData()

      set_承辦(form_data.承辦)
      set_備註(form_data.備註)
      
      set_台彩銷售(form_data.台彩銷售)
      set_台彩兌獎_電腦型(form_data.台彩兌獎_電腦型)
      set_台彩兌獎_立即型(form_data.台彩兌獎_立即型)
      set_運彩銷售(form_data.運彩銷售)
      set_運彩兌獎_有效(form_data.運彩兌獎_有效)
      set_運彩兌獎_無效(form_data.運彩兌獎_無效)

      set_現金0001(form_data.現金0001)
      set_現金0005(form_data.現金0005)
      set_現金0010(form_data.現金0010)
      set_現金0050(form_data.現金0050)
      set_現金0100(form_data.現金0100)
      set_現金0200(form_data.現金0200)
      set_現金0500(form_data.現金0500)
      set_現金1000(form_data.現金1000)
      set_現金2000(form_data.現金2000)

      set_預覽結帳單(form_data)

    }
    const 店休結帳= async() =>{
      await Axios.post("https://pos-back-end.pktriot.net/dayreport_create_re", {
        日期: 日期,
        門市: 門市,
        }).then(()=>{read_dayreport(日期)})
    }

    //#endregion
    // #region 讀取預設資料
    const loadData = async () => {
      await read_item()
      await read_member()
      await read_dayreport() 
      await read_dayinventory()
      await read_unauthorized()
      await 重載金物()

    }
    useEffect(() => {loadData().then(set_STATE_網頁讀取("已讀取"))}, [])
    
    
    
    // #endregion
    // #region TABLE_立即型 模組
    const onChange = (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
    };
    const [form_立即型_編輯] = Form.useForm()
    const table_edit_立即型 = (table_object) => {
      form_立即型_編輯.setFieldsValue({
        INVENTORY_ID: '',
        日期: '',
        門市: '',
        種類: '',
        數量: '',
        備註: '',
        ...table_object,
      });
    };

    const EditableCell_立即型 = ({ editing, dataIndex, title, inputType, record, index, children, inputNode, required, ...restProps }) => {


      return (
        <td {...restProps}>
          {editing ? ( 
              <InputNumber style={{width: 100}} size="small" defaultValue={record.數量} onBlur={ (e)=>{
                const value = e.target.value; // 獲取輸入框的值
                if(e === null){update_dayinventory(0,record)} 
                else{update_dayinventory(value,record)}}} 
              />
          ) : (
            children
          )}
        </td>
      );
    };
    const table_columns_立即型 = [
      {
        Key:'0',width: 50,
        title:'前期',
        dataIndex:'前期',
      },
      {
        Key:'0',width: 50,
        title:'批動',
        dataIndex:'批動',
      },
      {
        Key: '1',
        width: 230,
        title: '種類',
        dataIndex: '種類',
        sorter: (a, b) => a.種類 - b.種類,
        sortDirections: ['ascend', 'descend'],
        render: (text, record) => {
          // 根據面額的值設定顏色
          let color = '';
          switch (record.面額) {
            // case 2000:
            //   color = '#2C3E50 '; // 設定為紅色
            //   break;
            // case 1000:
            //   color = '#34495E'; // 設定為藍色
            //   break;
            // case 500:
            //   color = '#5D6D7E'; // 設定為綠色
            //   break;
            // case 300:
            //   color = '#85929E '; // 設定為橙色
            //   break;
            // case 200:
            //   color = '#B3C2C9  '; // 設定為紫色
            //   break;
            // case 100:
            //   color = '#D6DBDF  '; // 設定為粉色
            //   break;
            default:
              color = '#2C3E50'; // 預設顏色
          }
    
          // 返回帶顏色的 "種類" 文本
          return <strong style={{ color: color, fontSize: '15px' }}>{text}</strong>;
        }
      },
      {
        Key:'2',
        title:'後期',width: 50,editable:true,
        dataIndex:'數量',
      },
      {
        Key:'2.5',width: 50,
        title:'🔺',
        dataIndex:'理論銷售', 
        render: (_,table_object) => { table_edit_立即型(table_object)
          if(STATE_正在結帳){return(<span>❓</span>)}
          else {return(<strong>{table_object.理論銷售}</strong>)}
        }
      },
      {
        Key:'3',
        title:'備註',width: 100,
        dataIndex:'備註',
      },
      {
        Key:'4',
        title:'操作',width: 150,
    
        render: (_,table_object) => {
          table_edit_立即型(table_object)
          return STATE_正在結帳 ? 
          (  
            <span>
              <Button size="small" type="dashed" danger  onClick={() => delete_dayinventory(table_object)} style={{marginRight: 8}}> 刪除 </Button>
            </span>
          ) 
          : 
          (
            <span></span>     
          )
        }
      }  
    
    ]
    const mergedColumns_立即型 = table_columns_立即型.map((col) => {
      if (!col.editable) { return col }
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: STATE_正在結帳,
        }),
      };
    });
    // #endregion
    // #region TABLE_未授權 模組
        const [form_未授權] = Form.useForm()
        const [editingKey_未授權, seteditingKey_未授權] = useState('');
        const isEditing_未授權 = (table_object) => table_object.unauthorized_ID === editingKey_未授權;
        const table_delete_未授權 = (table_object) => {delete_unauthorized(table_object)}
        const table_cancel_未授權 = () => {seteditingKey_未授權('')}
        const table_edit_未授權 = (table_object) => {
          form_未授權.setFieldsValue({
            unauthorized_ID:'',
            種類: '',
            面額0796: '',
            面額08: '',
            銷售日期: '',
            入帳日期: '',
            入帳門市: '',
            有效日期: '',
            出帳日期: '',
            兌獎日期: '',
            備註: '',
            ...table_object,
          });seteditingKey_未授權(table_object.unauthorized_ID);
        }
        const table_save_未授權 = async (table_object) => {
            const row = await form_未授權.validateFields();
            const newData = [...unauthorized_list];
            const index = newData.findIndex((item) => table_object.unauthorized_ID === item.unauthorized_ID);
          try {if (index > -1) {const item = newData[index];newData.splice(index, 1, {...item,...row,});set_unauthorized_list(newData);} else {console.log("無資料")}} 
          catch (errInfo) {console.log('Validate Failed:', errInfo)}
          finally {seteditingKey_未授權('');update_unauthorized(newData[index])}
        }
        const EditableCell_未授權 = ({editing,dataIndex,title,inputType,record,index,children,inputNode,required, ...restProps}) => {
          if (dataIndex === '備註') { inputNode = <Input style={{ width:100 }} size="small"   type="text"  />} 
          else if (dataIndex === '種類' ) {inputNode = <Select showSearch style={{width: 150}}  size="small" optionFilterProp="children" filterOption={(input, option) => (option?.label ?? '').includes(input)} filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={item_list} />}
          return (<td {...restProps}> {editing ? (<Form.Item name={dataIndex}  style={{margin: 0,width:80}}  rules={[ {required: required !== false,message: `Please Input ${title}!`}]}>{inputNode}</Form.Item>) : (children)}</td>)
        }
        const table_columns_未授權 = [
          {
            Key:'0',width: 50,
            title:'#',
            dataIndex:'unauthorized_ID'
          },
          {
            Key:'1',width: 200,editable: true,required: false,
            title:'種類',
            dataIndex:'種類'
          },
          {
            Key:'2',width: 100,
            title:'面額0796',
            dataIndex:'面額0796'
          },
          {
            Key:'3',width: 100,
            title:'面額08',
            dataIndex:'面額08'
          },
          {
            Key:'4',
            title:'銷售日期',width: 100,
            dataIndex:'銷售日期'
          },
          {
            Key:'5',
            title:'入帳日期',width: 100,
            dataIndex:'入帳日期'
          },
          {
            Key:'6',
            title:'備註',width: 150,editable: true,
            dataIndex:'備註',
            render: (text, record) => {return < span style={{ color: '#0F3B53' , fontSize: '10px' }}>{text}</span>}
          },
          {
            Key:'7',
            title:'出帳日期',width: 200,
            dataIndex:'出帳日期'
          },
          {
            Key:'8',
            title:'操作',width: 200,
        
            render: (_,table_object) => {
        
              const editable = isEditing_未授權(table_object);
              return editable ? 
              ( <span>
                  <Typography.Link onClick={() => table_save_未授權(table_object)} style={{marginRight: 8}}> ✅更新 </Typography.Link>
                  <Typography.Link onClick={table_cancel_未授權} style={{marginLeft: 20}}> 🔵取消 </Typography.Link>
                </span>
              ) 
              : 
              (
                <span>
                <Typography.Link disabled={editingKey_未授權 !== ''} onClick={() => table_edit_未授權(table_object)}> 🔒編輯 </Typography.Link>
                <Typography.Link onClick={() => table_delete_未授權(table_object)} style={{marginRight: 8}}> 🟣出帳 </Typography.Link>
                </span>
              )
            }
          }  
        
        ]
        const mergedColumns_未授權 = table_columns_未授權.map((col) => {
          if (!col.editable) {return col}
          return {...col,onCell: (record) => ({record,dataIndex: col.dataIndex,title: col.title,editing: isEditing_未授權(record),}),};}
        )
        // #endregion
    
    // #region TAB 模組
    const Tab_items = [
      {key: '1',label: (<span><CodeSandboxOutlined />立即型</span>),
        children: 
    
        <Space direction="vertical">
        
        {STATE_正在結帳 && ( 
          <Space direction="horizontal">
            <Select showSearch style={{width: 200}} defaultValue="4001／檢查用／$100" optionFilterProp="children" filterOption={(input, option) =>(option?.label ?? "").includes(input)}
            filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={item_list}
            onChange={(event) => {set_種類(event)}} />
      
            <Button type="link" loading={loadings[2]} onClick={()=>{enterLoading(2) ; create_dayinventory()}} >【單品新增】重複品項會無反應</Button>
            <Button type="link" loading={loadings[3]} onClick={()=>{enterLoading(3) ; dayinventory_create_default()}}>【全品新增】新增所有上市品項</Button>
            <Button type="link" loading={loadings[4]} onClick={()=>{enterLoading(4) ; dayinventory_create_default_beta()}}>【重點新增】僅新增昨日帳上品項</Button>
            
          </Space>
        )}

            <Form form={form_立即型_編輯} component={false}>
              <Table size="small" components={{body: {cell: EditableCell_立即型}}} dataSource={dayinventory_list} columns={mergedColumns_立即型} pagination={{ pageSize: 100 }} onChange={onChange} rowKey={(record) => record.INVENTORY_ID} />
              
            </Form>
        </Space>
        ,
        
      }
      ,
      {key: '2',label: (<span><WarningOutlined />未授權</span>),
      children: 
    
      <Space direction="vertical">

    {STATE_正在結帳 && ( 
      <Space direction="horizontal">
        <Select showSearch style={{width: 200}} defaultValue="4001／檢查用／$100" optionFilterProp="children" filterOption={(input, option) =>(option?.label ?? "").includes(input)}
        filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={item_list}
        onChange={(event) => {set_種類_未授權(event)}} />
    
        <Input style={{ width: 'calc(90px)' }} placeholder="0.796"  type="number" onChange={(event) => {set_面額_0796(event.target.value)}}/>
        <Input style={{ width: 'calc(90px)' }} placeholder="0.8"  type="number" onChange={(event) => {set_面額_08(event.target.value)}}/>
        <DatePicker  format="YYYY-MM-DD" placeholder="銷售日期" style={{ width: 'calc(190px)' }} 
        onChange={(event) => {
          const dateString = event.$y+"/"+(event.$M+1)+"/"+event.$D;
          const date = new Date(dateString);
          const formattedDate = date.toLocaleDateString("zh-TW", {year: "numeric",month: "2-digit", day: "2-digit",}).replace(/\//g, "-");
          set_銷售日期(formattedDate)
          
          }} />
    
        <Input style={{ width: 'calc(150px)' }} placeholder="備註" type="text" onChange={(event) => {set_備註_未授權(event.target.value)}}/>
        <Button type="primary" icon={<CheckOutlined />} loading={loadings[1]}  onClick={() => {enterLoading(1) ;create_unauthorized()}} >新增項目</Button>
      </Space>
    )}

        <Form form={form_未授權} component={false}>
          <Table  components={{body: {cell: EditableCell_未授權}}}  dataSource={unauthorized_list} columns={mergedColumns_未授權}  pagination={{onChange: table_cancel_未授權}} /> 
        </Form>
      </Space>
    
      ,
        
      },
    ]
    
    //#endregion
    
    
    // #region 結帳狀態模組
    


    if (STATE_網頁讀取==="未讀取" ) {return <div><Statistic title="【系統訊息】" value={"(資料讀取中)"} prefix={<LoadingOutlined />} /></div>}
    else if  (STATE_網頁讀取==="已讀取" && dayreport_list.length===0) 
      {return  <div>

        <Statistic title="【系統訊息】" value={"日報表初始化中（⚠超過10秒為異常，請聯繫管理員）"} prefix={<LoadingOutlined />} />
          <Card style={{ width: 300,margin:15 }} >
            <p>若為前一日店休，可使用以下按鈕，將前前日的現值與刮樂庫存補上</p>
            <Button type="primary" icon={<CheckOutlined />} loading={loadings[1]}  onClick={() => {enterLoading(1) ; 店休結帳()}} >店休帳</Button>
          </Card>
          {/* <Card style={{ width: 300,margin:15 }} >
            <p>若為前一日忘記送出結帳，可填寫以下資料，並執行補結帳</p>
            <Button type="primary" icon={<CheckOutlined />} loading={loadings[1]}  onClick={() => {enterLoading(1) ; }} >補結帳</Button>
          </Card> */}

      </div>}
    else if  (STATE_網頁讀取==="已讀取" && dayreport_list.length===2) 
      {return  <div><Statistic title="【系統訊息】" value={"本日已結帳完成，請等待早上08:00開啟新的結帳頁面（⚠記得回到首頁登出，再重新登入）"} prefix={<LockOutlined />} /></div>}
    else return (<div className="App">

    
    <Space direction="vertical" >
      <Divider orientation="left">🈺現金流量表</Divider>
      
      <Space direction="horizontal" >
        <Paper elevation={3}  style={{ width: 185 }} >
        
            <Space direction="vertical" style={{ margin: 15 }}>
              <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>
                應有前期
              <InputNumber disabled value={dayreport_list[0].台幣現值+dayreport_list[0].等價現值+dayreport_list[0].刮樂現值} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
              <Divider /></p>
              <span>台幣現值：<InputNumber disabled value={dayreport_list[0].台幣現值} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
              <span>等價現值：<InputNumber disabled value={dayreport_list[0].等價現值} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
              <span>刮樂現值：<InputNumber disabled value={dayreport_list[0].刮樂現值} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
              <p style={{ fontSize: '12px' ,fontWeight: 'bold',textAlign: 'center'}}>　</p>
            </Space>
        
        </Paper>
        <div><p>➕</p></div>
        <Paper elevation={3}  style={{ width: 185 }} >
        
            <Space direction="vertical" style={{ margin: 15 }}>
              <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>
              額度增加
              <InputNumber disabled value={額度增加} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
              <Divider /></p>
              <span>台彩銷售：<InputNumber disabled value={台彩銷售} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
              <span>運彩銷售：<InputNumber disabled value={運彩銷售} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
              <span>金流代收：<InputNumber disabled value={金流代收} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
              <span>刮樂批入：<InputNumber disabled value={刮樂批入} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
            </Space>
        
        </Paper>
        <div><p>➖</p></div>
        <Paper elevation={3}  style={{ width: 185 }} >
        
            <Space direction="vertical" style={{ margin: 15 }}>
              <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>
              額度減少
              <InputNumber disabled value={額度減少} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
              <Divider /></p>
              <span>台彩兌獎：<InputNumber disabled value={台彩兌獎_電腦型+台彩兌獎_立即型} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
              <span>運彩兌獎：<InputNumber disabled value={運彩兌獎_有效+運彩兌獎_無效} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
              <span>金流代付：<InputNumber disabled value={金流代付} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
              <span>刮樂批出：<InputNumber disabled value={刮樂批出} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
            </Space>
        
        </Paper>
        <div><p>＝</p></div>
        <Paper elevation={3}  style={{ width: 185 }} >
        
            <Space direction="vertical" style={{ margin: 15 }}>
              <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>
              應有後期(a)
              <InputNumber disabled value={dayreport_list[0].台幣現值+dayreport_list[0].等價現值+dayreport_list[0].刮樂現值+額度增減} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
              <Divider /></p>
              <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>
              本日帳差(b-a)
              <InputNumber disabled value={盤點後期-(dayreport_list[0].台幣現值+dayreport_list[0].等價現值+dayreport_list[0].刮樂現值+額度增減)} style={{ width: 100 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></p>
              <p style={{ fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>　</p>
            </Space>
        
        </Paper>
        <div><p>　</p></div>
        
        <Paper elevation={3}  style={{ width: 185 }} >
          <Space direction="vertical" style={{ margin: 15 }}>
            <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>
            盤點後期(b)
            <InputNumber disabled value={盤點後期} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
            <Divider /></p>
            <span>台幣現值：<InputNumber disabled value={台幣現值} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
            <span>等價現值：<InputNumber disabled value={等價現值} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
            <span>刮樂現值：<InputNumber disabled value={刮樂現值} style={{ width: 85 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
            <p style={{ fontSize: '12px' ,fontWeight: 'bold',textAlign: 'center'}}>　</p>
          </Space>
        </Paper>
      
      </Space>

      <Divider orientation="left">🈺結帳登記表</Divider>


{STATE_正在結帳 && ( // 編輯結帳模式

<Space direction="vertical" >
<Form name="結帳單"  onFinish={async(form_data)=>{set_STATE_正在結帳(false);await 預覽結帳(form_data) }}>
  <Form.Item  >
    <Button icon={<CheckOutlined />} loading={loadings[5]} onClick={()=>{enterLoading(5); enterLoading(9)}} htmlType="submit" > 盤點完畢(下一步)  </Button>
  </Form.Item>
  <Divider></Divider>


    <Space direction="vertical" >
        <Space direction="horizontal" >
            
            <Paper elevation={3}  style={{ width: 200 }} >
        
            <Space direction="vertical" style={{ marginLeft: 15 }}>
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>結帳單<Divider /></p>
                
                <Row>
                <Col flex={1}>
                <Form.Item name="日期" initialValue={完整日期} rules={[{ required: true, message: '必填欄位' }]} label="日期：" style={{ display: 'flex', alignItems: 'center' }} >
                  <DatePicker style={{ width: 125 }} disabled format="YYYY-MM-DD"  />
                </Form.Item>
        

                <Form.Item name="門市" initialValue={門市} rules={[{ required: true, message: '必填欄位' }]} label="門市："  style={{ display: 'flex', alignItems: 'center' }} >
                  <Select disabled showSearch style={{ width: 125 }} placeholder="門市" optionFilterProp="children" filterOption={(input, option) => (option?.label ?? '').includes(input)}  filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase()) } />
                </Form.Item>

                <Form.Item name="承辦" initialValue={承辦} rules={[{ required: true, message: '必填欄位' }]} label="承辦："  style={{ display: 'flex', alignItems: 'center' }} >
                  <Select  showSearch style={{ width: 125 }} placeholder="承辦" optionFilterProp="children" filterOption={(input, option) => (option?.label ?? '').includes(input)} filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase()) } options={member_list_staff} />
                </Form.Item>

                <Form.Item name="備註" initialValue={備註} rules={[{ required: false, message: '必填欄位' }]} label="備註：" style={{ display: 'flex', alignItems: 'center' }} >
                  <TextArea  rows={1} placeholder="觀察/異常..." style={{ width: 125 }} />
                </Form.Item>

                
                </Col>
                </Row>
        
            </Space>
        
        
        
        
            </Paper>
        
            <div><p>　</p></div>
        
            <Paper elevation={3}  style={{ width: 825 }} >
            <Space direction="vertical" style={{ marginLeft: 15 }}>
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>報表與現金清點<Divider /></p>
        
        
                <Row>
                <Col flex={1}>
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>台彩</p>
                <Form.Item name="台彩銷售" initialValue={台彩銷售} rules={[{ required: true, message: '必填欄位' }]} label="台彩銷售：" style={{ display: 'flex', alignItems: 'center' }} >
                    <InputNumber  style={{ width: 85}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                <Form.Item name="台彩兌獎_電腦型" initialValue={台彩兌獎_電腦型} rules={[{ required: true, message: '必填欄位' }]} label="兌獎電腦：" style={{ display: 'flex', alignItems: 'center' }} >
                    <InputNumber  style={{ width: 85}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                <Form.Item name="台彩兌獎_立即型" initialValue={台彩兌獎_立即型} rules={[{ required: true, message: '必填欄位' }]} label="兌獎立即：" style={{ display: 'flex', alignItems: 'center' }}   >
                    <InputNumber  style={{ width: 85}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                </Col>
        
                <Col flex={1} style={{ marginLeft: 15 }} >
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>運彩</p>
                <Form.Item name="運彩銷售" initialValue={運彩銷售} rules={[{ required: true, message: '必填欄位' }]} label="運彩銷售：" style={{ display: 'flex', alignItems: 'center' }}   >
                    <InputNumber  style={{ width: 85}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                <Form.Item name="運彩兌獎_有效" initialValue={運彩兌獎_有效} rules={[{ required: true, message: '必填欄位' }]} label="兌獎有效：" style={{ display: 'flex', alignItems: 'center' }}   >
                    <InputNumber  style={{ width: 85}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                <Form.Item name="運彩兌獎_無效" initialValue={運彩兌獎_無效} rules={[{ required: true, message: '必填欄位' }]} label="兌獎無效：" style={{ display: 'flex', alignItems: 'center' }}   >
                   <InputNumber style={{ width: 85}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
        
                </Col>
                <Col flex={1} style={{ marginLeft: 15 }} >
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>現金</p>
                <Form.Item name="現金0001" initialValue={現金0001} rules={[{ required: true, message: '必填欄位' }]} label="$01：" style={{ display: 'flex', alignItems: 'center' }}   >
                   <InputNumber  style={{ width: 60}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                <Form.Item name="現金0005" initialValue={現金0005} rules={[{ required: true, message: '必填欄位' }]}  label="$05：" style={{ display: 'flex', alignItems: 'center' }}  >
                    <InputNumber  style={{ width: 60}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                <Form.Item name="現金0010" initialValue={現金0010} rules={[{ required: true, message: '必填欄位' }]}  label="$10：" style={{ display: 'flex', alignItems: 'center' }}  >
                    <InputNumber  style={{ width: 60}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                </Col>
                <Col flex={1} style={{ marginLeft: 15 }} >
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>　</p>
                <Form.Item name="現金0050" initialValue={現金0050} rules={[{ required: true, message: '必填欄位' }]}  label="$050：" style={{ display: 'flex', alignItems: 'center' }}  >
                    <InputNumber  style={{ width: 60}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                <Form.Item name="現金0100" initialValue={現金0100} rules={[{ required: true, message: '必填欄位' }]} label="$100：" style={{ display: 'flex', alignItems: 'center' }}   >
                    <InputNumber style={{ width: 60}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                <Form.Item name="現金0200" initialValue={現金0200} rules={[{ required: true, message: '必填欄位' }]}  label="$200：" style={{ display: 'flex', alignItems: 'center' }}  >
                    <InputNumber  style={{ width: 60}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                </Col>
                <Col flex={1} style={{ marginLeft: 15 }} >
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>　</p>
                <Form.Item name="現金0500" initialValue={現金0500} rules={[{ required: true, message: '必填欄位' }]}  label="$0500：" style={{ display: 'flex', alignItems: 'center' }}  >
                    <InputNumber  style={{ width: 60}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                <Form.Item name="現金1000" initialValue={現金1000} rules={[{ required: true, message: '必填欄位' }]}  label="$1000：" style={{ display: 'flex', alignItems: 'center' }}  >
                    <InputNumber  style={{ width: 60}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                <Form.Item name="現金2000" initialValue={現金2000} rules={[{ required: true, message: '必填欄位' }]} label="$2000：" style={{ display: 'flex', alignItems: 'center' }}   >
                    <InputNumber  style={{ width: 60}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/>
                </Form.Item>
                </Col>
                </Row>
            </Space>
            </Paper>
        

        </Space>

        <Divider></Divider> 
        <Tabs   type="card" style={{ marginLeft: "20px" }} defaultActiveKey="1" items={Tab_items}  />

    </Space>
</Form>

</Space> 

)}


{STATE_正在結帳 === false && ( // 預覽結帳模式
<Space direction="vertical" >

    <Space direction="horizontal" >
      <Button icon={<CheckOutlined />} onClick={()=>{set_STATE_正在結帳(true)}}>上一步</Button>
      <Button icon={<CheckOutlined />} loading={loadings[9]} onClick={()=>{ enterLoading(9) ; 完成結帳(預覽結帳單)}} >完成結帳</Button>
      <span>【{承辦}】為本日【{日期}】結帳負責人（本按鈕送出後，如有帳目異動請洽管理員）"</span>
    </Space>
    <Divider></Divider>
    <Space direction="horizontal" >
            
            <Paper elevation={3}  style={{ width: 200 }} >
        
            <Space direction="vertical" style={{ marginLeft: 15 }}>
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>結帳單<Divider /></p>
                
                <Row>
                <Col flex={1}>
                <Form.Item name="日期"  rules={[{ required: false, message: '必填欄位' }]}  >
                    <span>日期：<DatePicker style={{width: 125}} disabled value={完整日期} format="YYYY-MM-DD" /></span>
                </Form.Item>
        
                <Form.Item name="門市" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>門市：<Select disabled value={門市} showSearch style={{width: 125}} placeholder="門市" optionFilterProp="children" filterOption={(input, option) =>(option?.label ?? "").includes(input)}
                    filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}  /></span>
                </Form.Item>
        
                <Form.Item name="承辦" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>承辦：<Select disabled value={承辦} showSearch style={{width: 125}} placeholder="承辦" optionFilterProp="children" filterOption={(input, option) =>(option?.label ?? "").includes(input)}
                    filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={member_list_staff} 
                    /></span>
                </Form.Item>
                <Form.Item name="備註"  value={備註} rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>備註：</span><TextArea disabled rows={1} placeholder="觀察/異常..."  style={{ width: 125}} />
                </Form.Item>
                </Col>
                </Row>
        
            </Space>
        
        
        
        
            </Paper>
        
            <p>　</p>
        
            <Paper elevation={3}  style={{ width: 825 }} >
            <Space direction="vertical" style={{ marginLeft: 15 }}>
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>報表與現金清點<Divider /></p>
        
        
                <Row>
                <Col flex={1}>
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>台彩</p>
                <Form.Item name="台彩銷售" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>台彩銷售：<InputNumber disabled value={台彩銷售} style={{ width: 100}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                <Form.Item name="台彩兌獎_電腦型" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>兌獎電腦：<InputNumber disabled value={台彩兌獎_電腦型} style={{ width: 100}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                <Form.Item name="台彩兌獎_立即型" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>兌獎立即：<InputNumber disabled value={台彩兌獎_立即型} style={{ width: 100}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                </Col>
        
                <Col flex={1} style={{ marginLeft: 15 }} >
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>運彩</p>
                <Form.Item name="運彩銷售" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>運彩銷售：<InputNumber disabled value={運彩銷售} style={{ width: 100}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                <Form.Item name="運彩兌獎_有效" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>兌獎有效：<InputNumber disabled value={運彩兌獎_有效} style={{ width: 100}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                <Form.Item name="運彩兌獎_無效" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>兌獎無效：<InputNumber disabled value={運彩兌獎_無效} style={{ width: 100}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
        
                </Col>
                <Col flex={1} style={{ marginLeft: 15 }} >
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>現金</p>
                <Form.Item name="現金0001" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$01：<InputNumber disabled value={現金0001} style={{ width: 75}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                <Form.Item name="現金0005" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$05：<InputNumber disabled value={現金0005} style={{ width: 75}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                <Form.Item name="現金0010" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$10：<InputNumber disabled value={現金0010} style={{ width: 75}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                </Col>
                <Col flex={1} style={{ marginLeft: 15 }} >
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>　</p>
                <Form.Item name="現金0050" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$050：<InputNumber disabled value={現金0050} style={{ width: 75}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                <Form.Item name="現金0100" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$100：<InputNumber disabled value={現金0100} style={{ width: 75}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                <Form.Item name="現金0200" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$200：<InputNumber disabled value={現金0200} style={{ width: 75}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                </Col>
                <Col flex={1} style={{ marginLeft: 15 }} >
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>　</p>
                <Form.Item name="現金0500" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$0500：<InputNumber disabled value={現金0500} style={{ width: 75}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                <Form.Item name="現金1000" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$1000：<InputNumber disabled value={現金1000} style={{ width: 75}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                <Form.Item name="現金2000" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$2000：<InputNumber disabled value={現金2000} style={{ width: 75}} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={(value) => value.replace(/\$\s?|(,*)/g, '')}/></span>
                </Form.Item>
                </Col>
                </Row>
            </Space>
            </Paper>
        

    </Space>
    
    <Divider></Divider> 
    <Tabs   type="card" style={{ marginLeft: "20px" }} defaultActiveKey="1" items={Tab_items}  />

</Space>
)}

</Space>
    

    
    
    

    
</div>)
// #endregion
    
}

  
