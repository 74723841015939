//#region ⭐模組需求套件--------------------------------------------------------
import 'antd/dist/reset.css';
import { UserOutlined,AlertOutlined,LockOutlined,PlusOutlined } from '@ant-design/icons';
import { Space , Button, Select, Table , Input , InputNumber,Typography,Form,Statistic  } from "antd";
import { useState,useEffect } from "react";
import Axios from "axios";
//#endregion 
export default 物流模組
function 物流模組(adminvalue)
{

// #region 前端LIST
  const [logistic_list, set_logistic_list] = useState([]);
  const [門市, set_門市] = useState(adminvalue.adminvalue[1]);
// #endregion

//#region ITEM 模組
const [item_list , set_item_list] = useState([]);
const read_item = async() => {
  let response = await Axios.get("https://pos-back-end.pktriot.net/item_read") 
  const array = response.data.filter((item) => item.啟用狀態 === 1) 
  const newarray = array.map((item) => {return {value:item.items_name,label:item.items_name}})
  set_item_list(newarray)
}
// #endregion

//#region MEMBER 模組
const [member_list , set_member_list] = useState([]);
  const [member_list_staff , set_member_staff_list] = useState([]);
  const read_member = async() => {
    let response = await Axios.put("https://pos-back-end.pktriot.net/member_read",{門市: 門市,})
    const newarray = response.data.map((item) => {return {value:item.名稱,label:item.名稱 ,}})
    set_member_list(newarray)
    const staff = newarray.filter((item) => item.label.startsWith('ST'));
    set_member_staff_list(staff)
  
  }
// #endregion
// #region CRUD功能集



const create_logistic = (table_object) => {
  Axios.post("https://pos-back-end.pktriot.net/logistic_create", {
    物品: table_object.物品,
    門市: 門市,
    承辦: table_object.承辦,
    交易類型: table_object.交易類型,
    存貨減少: table_object.存貨減少,
    存貨增加: table_object.存貨增加,
    備註: table_object.備註,
  }).then(read_logistic)
}
const read_logistic = () => {
  Axios.put("https://pos-back-end.pktriot.net/logistic_read", { 
    日期: adminvalue.adminvalue[0],
    門市: adminvalue.adminvalue[1],
  }).then((response) => {
  //時區置換
  Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp).toLocaleString('zh-TW', {hour12: false});});
  set_logistic_list(response.data)})
  console.log(adminvalue)
}
const update_logistic = (table_object) => {
  
  Axios.put(`https://pos-back-end.pktriot.net/logistic_update`, { 
    LOGISTIC_ID: table_object.LOGISTIC_ID,
    物品: table_object.物品,
    門市: table_object.門市,
    承辦: table_object.承辦,
    交易類型: table_object.交易類型,
    存貨減少: table_object.存貨減少,
    存貨增加: table_object.存貨增加,
    備註: table_object.備註,
  }).then(read_logistic)
}
const delete_logistic = (table_object) => {

  Axios.delete(`https://pos-back-end.pktriot.net/logistic_delete/${table_object.LOGISTIC_ID}`).then((response) => {
   set_logistic_list( pre => {pre.filter((logistic) => {return logistic.LOGISTIC_ID !== table_object.LOGISTIC_ID})})}).then(read_logistic)
   setEditingKey('')

}
// #endregion
// #region BUTTON LOADING  模組
const [loadings, setLoadings] = useState([]);
const enterLoading = (index) => {
  setLoadings((prevLoadings) => {
    const newLoadings = [...prevLoadings];
    newLoadings[index] = true;
    return newLoadings;
  });
  setTimeout(() => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = false;
      return newLoadings;
    });
  }, 2000);
};
// #endregion
// #region Table 模組
const table_columns = [
  {
    Key:'0',width: 180,
    title:'交易時間',
    dataIndex:'TIMESTAMP_ID'
  },
  {
    Key:'1',width: 250,editable: true,required: false, // 指定 required 屬性為 false
    title:'物品',
    dataIndex:'物品'
  },
  {
    Key:'2',width: 160,editable: true,required: false,
    title:'門市',
    dataIndex:'門市'
  },
  {
    Key:'3',
    title:'承辦',width: 160,editable: true,required: false,
    dataIndex:'承辦'
  },
  {
    Key:'4',
    title:'交易類型',width: 210,editable: true,required: false,
    dataIndex:'交易類型'
  },
  {
    Key:'5',
    title:'存貨減少',width: 160,editable: true,
    dataIndex:'存貨減少',

    render: (text, record) => {return <strong style={{ color: '#4285f4' , fontSize: '20px' }}>{text}</strong>}
  },
  {
    Key:'6',
    title:'存貨增加',width: 160,editable: true,
    dataIndex:'存貨增加',

    render: (text, record) => {return <strong style={{ color: '#EB6472' , fontSize: '20px' }}>{text}</strong>}
  },
  {
    Key:'7',
    title:'備註',width: 200,editable: true,
    dataIndex:'備註',
    render: (text, record) => {return < span style={{ color: '#0F3B53' , fontSize: '10px' }}>{text}</span>}
  }, 
  {
    Key:'8',
    title:'操作',

    render: (_,table_object) => {

      const editable = isEditing(table_object);
      return editable ? 
      ( <span>
          <Typography.Link onClick={() => table_save(table_object)} style={{marginRight: 8}}> ✅更新 </Typography.Link>
          <Typography.Link onClick={() => delete_logistic(table_object)} style={{marginRight: 8}}> ❌刪除 </Typography.Link>
          <Typography.Link onClick={table_cancel} style={{marginLeft: 20}}> 🔵取消變更 </Typography.Link>
        </span>
      ) 
      : 
      (
        <Typography.Link disabled={editingKey !== ''} onClick={() => table_edit(table_object)}> 🔒編輯 </Typography.Link>
      )
    }
  }  

]
const deal_type = [
  {
    value: '立即型',
    label: '立即型',
  },
  {
    value: '營業用',
    label: '營業用',
  },

]

// #endregion
// #region Table Editible 模組
const [form] = Form.useForm();const [editingKey, setEditingKey] = useState('');
  const isEditing = (table_object) => table_object.LOGISTIC_ID === editingKey;
  const table_cancel = () => {setEditingKey('')}
  const table_edit = (table_object) => {
    form.setFieldsValue({
      TIMESTAMP_ID: '',
      物品: '',
      門市: '',
      承辦: '',
      交易類型: '',
      存貨減少: '',
      存貨增加: '',
      備註: '',
      ...table_object,
    });setEditingKey(table_object.LOGISTIC_ID);
  };
  const table_save = async (table_object) => {
      const row = await form.validateFields();
      const newData = [...logistic_list];
      const index = newData.findIndex((item) => table_object.LOGISTIC_ID === item.LOGISTIC_ID);
    try {
      
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        set_logistic_list(newData);
      } else {
        console.log("無資料")
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
    finally {
      setEditingKey('')
      console.log(newData[index])
      update_logistic(newData[index])

    }
  }
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    inputNode,
    required, // 新增 required 屬性
    ...restProps
  }) => {
    

    if (dataIndex === '備註') {
      inputNode = <Input style={{ width:150 }} size="small"   type="text"  />
    } else if (dataIndex === '存貨減少'||dataIndex === '存貨增加') {
      inputNode = <InputNumber style={{width: 100}} size="small" />
    } else if (dataIndex === '物品' ) {
      inputNode = <Select showSearch style={{width: 200}}  size="small" optionFilterProp="children" filterOption={(input, option) => (option?.label ?? '').includes(input)}
      filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={item_list} />
    } else if (dataIndex === '門市' || dataIndex === '承辦' ) {
      inputNode = <Select showSearch style={{width: 130}}  size="small" optionFilterProp="children" filterOption={(input, option) => (option?.label ?? '').includes(input)}
      filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={member_list_staff} />
    } else if (dataIndex === '交易類型') {
      inputNode = <Select showSearch style={{width: 150}} size="small" optionFilterProp="children" filterOption={(input, option) => (option?.label ?? '').includes(input)}
      filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={deal_type} />
    } 

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item name={dataIndex}  style={{margin: 0,width:80}}  rules={[ {required: required !== false,message: `Please Input ${title}!`}]}> 
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  const mergedColumns = table_columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex ==='存貨增加' || col.dataIndex ==='存貨減少' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
// #endregion

// #region 網頁起始函數
useEffect(() => {
  read_item()
  read_member()
  read_logistic() 
}, []);

// #endregion

// #region 結帳狀態模組
const now = new Date(); 
const isBefore8am = false;
// const isBefore8am = now.getHours() < 7;

if (isBefore8am) {return <div><Statistic title="【系統訊息】" value={"超過本日結轉時間，若有金物流帳目異動需調整，請至群組告知管理員。"} prefix={<LockOutlined />} /></div>}
else return (<div className="App">

<Space direction="vertical" style={{ marginTop: 50 }}>
  
   <Form name="logistic_creation"  initialValues={{remember: true}} onFinish={create_logistic}>
  <Space direction="horizontal">
    <Form.Item name="物品" rules={[{ required: true, message: '必填欄位' }]}  style={{ marginLeft: 180 }}>
      <Select showSearch style={{width: 250}} suffixIcon={<UserOutlined/>} placeholder="物品" optionFilterProp="children" filterOption={(input, option) =>(option?.label ?? "").includes(input)}
      filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={item_list} />
    </Form.Item>
    <Form.Item name="門市" initialValues={門市} rules={[{required: false,message: '必填欄位'}]}>
      <Input  style={{width: 150}} suffixIcon={<AlertOutlined />} defaultValue={門市} disabled  />
    </Form.Item>
    <Form.Item name="承辦" rules={[{required: true,message: '必填欄位'}]}>
      <Select showSearch style={{width: 150}} suffixIcon={<AlertOutlined />} placeholder="承辦" optionFilterProp="children" filterOption={(input, option) =>(option?.label ?? "").includes(input)}
      filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={member_list_staff} />
    </Form.Item>  

    <Form.Item name="交易類型" rules={[{required: true,message: '必填欄位'}]}>
      <Select showSearch style={{width: 205}} placeholder="交易類型" optionFilterProp="children" filterOption={(input, option) => (option?.label ?? '').includes(input)}
      filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={deal_type}/>
    </Form.Item>
    <Form.Item name="存貨減少" rules={[{required: false,message: '必填欄位'}]}>
      <Input style={{ width: 'calc(150px)' }} placeholder="存貨減少"  type="number" />
    </Form.Item>  
    <Form.Item name="存貨增加" rules={[{required: false,message: '必填欄位'}]}>
      <Input style={{ width: 'calc(150px)' }} placeholder="存貨增加"  type="number" />
    </Form.Item>
    <Form.Item name="備註" rules={[{required: false,message: '必填欄位'}]}>
      <Input style={{ width: 'calc(200px)' }} placeholder="備註"  type="text" />
    </Form.Item>

    <Form.Item>
      <Button type="primary"  htmlType="submit" icon={<PlusOutlined />}  loading={loadings[1]} onClick={() => enterLoading(1)} >新增</Button>
    </Form.Item>
  </Space>
</Form>




  <Form form={form} component={false}>
  <Table size="small" components={{body: {cell: EditableCell}}} bordered dataSource={logistic_list} columns={mergedColumns} rowClassName="editable-row" pagination={{onChange: table_cancel}} />
  </Form>
  
</Space>
    </div>)
    
}